import React, { useEffect, useState } from 'react'
import { faArrowLeft, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Container, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ExamServices } from '../../../Services/examServices';
import AddQuestion from './AddQuestion';
import StatementIndex from '../Exam/Statement/Statement.index.';
import OptionsIndex from '../Exam/Options/Options.index';
import CardBasic from '../../../components/Cards/Card.basic';
import QuestionStatement from './QuestionStatement';
import QuestionImage from './QuestionImage';
import QuestionAsImage from './QuestionAsImage';

export default function QuestionDetails() {

    const params: any = useParams();
    const navigate = useNavigate();
    const [questionData, setQuestionData] = useState<any>([]);
    const [tabs, setTabs] = useState("value");
    const [searchParams, setSearchParams] = useSearchParams();



    const getQuestionData = async () => {
        ExamServices.getQuestionById(params.questionId)
            .then((res) => {
                if (res.status === 200) {
                    setQuestionData(res.data.data);
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        getQuestionData();
    }, []);

    return (
        <>
            <Container>
                <div className="d-flex align-items-center p-3 ">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate("/admin-dash/exam/getExamQuestions/" + params.examId)} className="me-2 cursor-pointer" />{" Back  "}
                </div>

                <CardBasic
                    body={
                        <>
                            <div className="">
                                <h5>Exam: <b>{searchParams.get('examName')}</b></h5>
                                {searchParams.has('section') && <h6>Section: <b>{searchParams.get('section')}</b></h6>}
                                {searchParams.has('questionIndex') && <h6>Question: <b>{searchParams.get('questionIndex')}</b></h6>}
                            </div>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={tabs}
                                onSelect={(k: any) => setTabs(k)}
                                className="mb-3"
                            >
                                <Tab eventKey="value" title="Question Dicom">
                                    <AddQuestion />
                                </Tab>
                                <Tab eventKey="option" title="Options Data">
                                    <OptionsIndex />
                                </Tab>
                                <Tab eventKey="statement" title="Question Statement">
                                    <QuestionStatement />
                                </Tab>
                                <Tab eventKey="image" title="Question Image">
                                    <QuestionImage />
                                </Tab>
                                <Tab eventKey="questionAsImage" title="Question As Image">
                                    <QuestionAsImage />
                                </Tab>
                            </Tabs>
                        </>
                    }
                />

            </Container>
        </>
    )
}