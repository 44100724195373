import makeRequest, { makeParams } from "../api/makeRequest";
import makeUploadRequest from "../api/uploadRequest";
import url from "../api/urls";
import { RequstMethods } from "../api/requestMethode";
import downloadRequest from "api/downloadRequest";

export class ResultSerivces {

    static async getResultResponse(examId: any, userId: any) {
        return await makeRequest(url.result.getResultResponse + "/" + examId + "/" + userId, RequstMethods.GET)
    }

    static async getEncryptedOptionById(examId: any, userId: any) {
        return await makeRequest(url.result.getEncryptedOptionById + "/" + examId + "/" + userId, RequstMethods.GET)
    }

    static async createUserResultCSVByExamId(examId: string) {
        return await makeRequest(url.result.createUserResultCSVByExamId + "/" + examId, RequstMethods.GET);
    }

    static async createUserResult(mappingId: string) {
        return await makeRequest(url.result.createUserResult + "/" + mappingId, RequstMethods.GET);
    }

    static async markQuestionResult(examId: any, questionId: any, payload: any) {
        return await makeRequest(url.result.ans_mark + "/" + examId + "/" + questionId, RequstMethods.POST, payload);
    }

    static async getAllAnswersByExamId(examId: any) {
        return await makeRequest(url.result.getQuestionAnswersByExamId + "/" + examId, RequstMethods.GET);
    }

    static async generateResult(examId: string, payload: any) {
        return await makeRequest(url.result.generateResult + "/" + examId, RequstMethods.POST, payload)
    }

    static async downloadExistingResultZip(examId: string) {
        return await downloadRequest(url.result.downloadExistingResultZip + "/" + examId, RequstMethods.GET)
    }
}