import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Form, Table } from 'react-bootstrap'
import { ExamServices } from '../../../Services/examServices'
import { toast } from 'react-toastify'
import CardBasic from '../../../components/Cards/Card.basic'
import { errorResponseHelper } from '../../../helpers/request.helper'
import { useLoader } from '../../../components/Loader/Loader'
import QuestionDicomTable from 'components/Tables/QuestionDicomTable'
import QuestionImageTable from 'components/Tables/QuestionImage.table'
import ImageCropperModal from 'components/Cropper/ImageCropperModal'

export default function QuestionAsImage() {

    const params: any = useParams();

    const fileTypes = ["image"]

    const [selectValue, setSelectValue] = useState<any>(fileTypes[0]);

    const [selectFiles, setSelectFiles] = useState<any>("");
    const [questionData, setQuestionData] = useState<any>();
    const [examLanguages, setExamLanguages] = useState<string[]>([]);

    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    const { loading, setLoading } = useLoader();
    const [showImageCropper, setShowImageCropper] = useState<boolean>(false);

    const handleFileInput = (e: any) => {
        setSelectFiles(e.target.files);
    }


    const uploadHandler = async (e: any) => {
        // setLoading(true);
        e.preventDefault();
        const formData = new FormData();
        // for (const filesData of selectFiles) {
        //     console.log(filesData ,"to upload")
        //     formData.append("answerdata", filesData);
        // }
        formData.append("answerdata", selectFiles);
        formData.append("type", 'questionasimage');
        if (selectedLanguage) formData.append("language", selectedLanguage);

        await ExamServices.addQuestionToQuestion(params.questionId, formData).then((res) => {
            if (res.status == 200) {
                toast.success("Question added successfully")
                getQuestionByQuestionId()
            }
        }).catch((err) => {
            errorResponseHelper(err)
        })
        setLoading(false);

    }

    const getQuestionByQuestionId = async () => {
        await ExamServices.getQuestionById(params.questionId).then(res => {
            if (res.status == 200) {
                setQuestionData(res.data.data)
            }

        }).catch(e => {
            errorResponseHelper(e)
        })
    }

    const getExamLanguages = async () => {
        await ExamServices.getExamLanguage(params.examId).then(res => {
            if (res.status == 200) {
                setExamLanguages(res.data.data)
                setSelectedLanguage(res.data.data[0])
            }
        })
    }

    useEffect(() => {
        getQuestionByQuestionId();
        getExamLanguages();
    }, [])


    return (
        <>
            <Container className="border rounded p-3" style={{ width: "50%" }}>
                <Form >
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Select Images</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={handleFileInput}
                            name="answerdata"
                        /> */}
                        <Button variant="primary" onClick={() => setShowImageCropper(true)}>Upload Image</Button> <br />
                        <Form.Text muted>
                            Data type must be only png jpeg jpg.
                        </Form.Text>
                        <div className="text-danger">
                            <b>Note: Donot zip data before uploading</b>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Select files type</Form.Label>
                        <Form.Select
                            value={selectValue}
                            name="type"
                            onChange={(e) => setSelectValue(e.target.value)}
                        >
                            {fileTypes.map((type: string) => {
                                return <option value={type}>{type.toUpperCase()}</option>
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Language</Form.Label>
                        <Form.Select
                            value={selectedLanguage}
                            name="type"
                            onChange={(e) => setSelectedLanguage(e.target.value)}
                        >
                            {
                                examLanguages.map((type: string, index: number) => {
                                    return <option value={type}>{type.toUpperCase()}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>

                    <Button type="submit" onClick={uploadHandler}>Add</Button>
                </Form>
            </Container>
            <Container>
                <div className="mt-4">
                    <h5>Uploaded Question Images</h5>
                    <CardBasic
                        body={
                            <>
                                <div>
                                    {questionData && questionData?.questionasImage?.length > 0 ?
                                        questionData?.questionasImage?.map((data: any, index: any) => {
                                            return <QuestionImageTable data={data} reload={getQuestionByQuestionId} questionId={params.questionId} questionAsImage={true} />
                                        }) : <span>No Question Uploaded</span>}
                                </div>
                            </>
                        }
                        cardClass="mt-3"
                    />
                </div>
            </Container>
            <ImageCropperModal
                show={showImageCropper}
                setShow={setShowImageCropper}
                setImageFile={(data: any) => { setSelectFiles(data) }}
                aspectRatio={16 / 9}
            />
        </>

    )
}