import React, { createRef, useState } from 'react'
import { Button, Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import { Cropper, ReactCropperElement } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faRotate } from '@fortawesome/free-solid-svg-icons';

interface ImageCropperProps {
    show: boolean;
    setShow: any;
    setImageFile: any;
    aspectRatio: number;
}

export default function ImageCropper({ show, setShow, setImageFile, aspectRatio }: ImageCropperProps) {

    const [image, setImage] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const cropperRef = createRef<ReactCropperElement>();
    const [progress, setProgress] = useState<number>(0);

    const onChange = (e: any) => {
        e.preventDefault();
    console.log(e.target.files?.[0], "e from files");
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    function handleModalClose() {
        setShow(false);
        setImage('');
    }

    async function handleFileUpload() {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            const canvas = cropperRef.current?.cropper.getCroppedCanvas();
            // const base64URL = canvas.toDataURL(); // Get base64 URL
            const blobPromise = new Promise<Blob | null>((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                });
            });
            const blob = await blobPromise;

            if (blob) {
                // console.log({ base64: base64URL, size: blob.size, blob: blob });
                setImageFile(blob);
                handleModalClose();
            }
        }
    }

    const handleRotate = () => {
        if (cropperRef.current?.cropper) {
            cropperRef.current.cropper.rotate(90);
        }
    };

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                animation={true}
                backdrop="static"
                style={{ width: "100%" }}
                centered
                show={show}
                onHide={handleModalClose}
            >
                <Modal.Body>
                    <div className='mt-2'>
                        <div className="d-flex flex-row justify-content-between align-items-start">
                            <h5>Crop and Upload Image</h5>
                            <FontAwesomeIcon icon={faClose} onClick={() => { setShow(!show) }} cursor='pointer' />
                        </div>
                    </div>
                    <Row>
                        <Col md={12} >
                            <Form.Group className="mb-3">
                                <Form.Label>Choose File</Form.Label>
                                <Form.Control type="file" onChange={onChange} accept="image/*" />
                            </Form.Group>
                            <Form.Group>
                                <div className="d-flex align-items-center gap-3">
                                    <span className="text-muted cursor-pointer" onClick={() => handleRotate()} style={{ fontWeight: "500" }}>Rotate</span>
                                    <FontAwesomeIcon icon={faRotate} className="fs-5" />
                                </div>
                            </Form.Group>
                            <div className="mt-3 d-flex justify-content-center align-items-center">
                                <Cropper
                                    ref={cropperRef}
                                    zoomTo={0.5}
                                    initialAspectRatio={16/9}
                                    preview=".img-preview"
                                    src={image}
                                    viewMode={1}
                                    cropBoxResizable={true}
                                    zoomable={true}
                                    background={false}
                                    responsive={true}
                                    checkOrientation={true}
                                    guides={false}
                                    // aspectRatio={aspectRatio}
                                    rotatable={true}
                                    width={'440px'}
                                />
                            </div>
                        </Col>
                        {
                            progress > 0 &&
                            <Col md={12} className='mt-3' >
                                <ProgressBar className="mt-3" animated variant="warning" now={progress} label={<span className="text-dark fw-bold">{progress}%</span>} max={100} />
                            </Col>
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose} variant="outline-danger" size="sm">Close</Button>
                    <Button onClick={handleFileUpload} variant="secondary" size="sm">
                        {
                            loading ? "Uploading..." : "Upload"
                        }
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}
