import { faArrowLeft, faPlusSquare, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuestionTable from 'components/Tables/QuestionTable';
import React, { useEffect, useState } from 'react';
import { Container, Form, Nav, Tab, Table, Tabs } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableRows from 'view/private/Exam/SelectedExam/TableRows';
import CardBasic from '../../../../components/Cards/Card.basic';
import SliderModal from '../../../../components/Modals/Slider.modal';
import Toolbar from '../../../../components/Toolbar/Toolbar';
import { errorResponseHelper } from '../../../../helpers/request.helper';
import { ExamServices } from '../../../../Services/examServices';
import SingleQuestion from '../SingleQuestion';

export default function ExamQuestionIndex() {

    const params: any = useParams();
    const navigate = useNavigate();


    const [questions, setQuestions] = useState<any>([]);
    const [examData, setExamData] = useState<any>();
    const [show, setShow] = useState<boolean>(false);
    const [questionTypes, setQuestionTypes] = useState<any>([]);
    const [optionType, setOptionType] = useState<any>([])
    const [selectQuestionType, setSelectQuestionType] = useState<any>()
    const [selectOptionType, setSelectOptionType] = useState<any>("")
    const [selectSection, setSelectSection] = useState<string>();
    const [sections, setSections] = useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [selectedSection, setSelectedSection] = useState<any>();


    const getQuestionTypes = async () => {
        await ExamServices.getQuestionTypes()
            .then((res) => {
                if (res.status === 200) {
                    setQuestionTypes(res.data.data);
                    setSelectQuestionType(res.data.data[0]);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }


    const getOptiontype = async () => {
        await ExamServices.getOptionType()
            .then((res) => {
                if (res.status === 200) {
                    setOptionType(res.data.data);
                    setSelectOptionType(res.data.data[0]);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }


    const createNewQuestion = async () => {
        if (!selectSection) return false
        await ExamServices.addQuestionToExam(params.examId, selectSection, {
            type: selectQuestionType,
            optionType: selectOptionType
        }).then((res) => {
            if (res.status === 200) {
                toast.success("Added New Question");
                setShow(false)
            }
        }).catch(e => {
            errorResponseHelper(e)
        })
    }

    const getExamDetails = async () => {
        await ExamServices.getExamById(params.examId).then((res => {
            if (res.status === 200) {
                setExamData(res.data.data)
                setSections(res.data.data.sections)
                setSelectSection(res.data.data.sections[0]._id)

                getSectionQuestions()
                setSearchParams({ section: res.data.data.sections?.[0]?.name, examName: res.data.data.examName })
            }
        })).catch(e => {
            errorResponseHelper(e)
        })
    }



    useEffect(() => {
        getExamDetails();
        getQuestionTypes();
        getOptiontype();
    }, []);



    const getSectionQuestions = async () => {

        if (!selectSection) return false
        await ExamServices.getQuestionsByExamIdSectionId(params.examId, selectSection).then((res) => {
            if (res.status === 200) {
                setQuestions(res.data)
            }
        })
    }

    function handleViewClick(examId: string, questionId: string, questionIndex: number) {
        searchParams.append("questionIndex", questionIndex.toString());
        navigate({
            pathname: '/admin-dash/exam/' + examId + '/questions/' + questionId,
            search: searchParams.toString()
        });
    }

    useEffect(() => {

        getSectionQuestions();
        setSearchParams({ section: sections?.find((item:any) => item?._id === selectSection)?.name , examName: examData?.examName })

    }, [selectSection, show])

    return (
        <>
            <SliderModal
                show={show}
                setShow={setShow}
                saveBtn="Add Question"
                body={
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Section</Form.Label>

                            <Form.Select
                                value={selectSection}
                                name="type"
                                onChange={(e: any) => setSelectSection(e.target.value)}
                            >
                                {sections && sections.length > 0 && sections.map((data: any) => (
                                    <option value={data._id}>{data.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Question Type</Form.Label>
                            <Form.Select
                                value={selectQuestionType}
                                name="type"
                                onChange={(e: any) => setSelectQuestionType(e.target.value)}
                            >
                                {questionTypes && questionTypes.length > 0 && questionTypes.map((type: any, i: any) => (
                                    <option value={type}>{type.toUpperCase()}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Option Type</Form.Label>

                            <Form.Select
                                value={selectOptionType}
                                name="type"
                                onChange={(e: any) => setSelectOptionType(e.target.value)}
                            >
                                {optionType && optionType.length > 0 && optionType.map((type: any, i: any) => (
                                    <option value={type}>{type.toUpperCase()}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>



                    </>
                }
                title="Question Type"
                onSave={() => selectQuestionType && selectOptionType && createNewQuestion()}
            />

            <Container className='mb-3'>
                <div className="d-flex justify-content-start align-items-center mt-2">
                    <a onClick={() => navigate("/admin-dash/exam")} className="cursor-pointer text-secondary">
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Back
                    </a>
                </div>

                <CardBasic
                    body={

                        <>
                            <div className='d-flex justify-content-between'>

                                <h5>Exam: <b>{examData && examData.examName}</b></h5>
                                <Toolbar
                                    values={
                                        [
                                            {
                                                value: <>
                                                    <FontAwesomeIcon icon={faPlusSquare} size="sm" className="me-2" />
                                                    Add Question
                                                </>,
                                                onClick: () => { sections.length > 0 ? setShow(true) : toast.error("Sections are required! Please add Exam Sections to continue") },
                                                variant: "primary"
                                            }
                                        ]
                                    }
                                    size="sm"
                                />
                            </div>
                            <div className="mt-3">

                                {sections && sections.length > 0 &&
                                    <Tabs defaultActiveKey={sections[0]._id} id="uncontrolled-tab-example" className="mb-3" onSelect={(e: any) => setSelectSection(e)}>
                                        {sections.map((section: any) => {
                                            return <Tab eventKey={section._id} title={section.name} />

                                        })}
                                    </Tabs>
                                }

                                <Table striped borderless className="xrg-table" size="sm" responsive="md">
                                    <thead>
                                        <tr>
                                            <th>Question No.</th>
                                            <th>Question Id</th>
                                            <th>Question Type</th>
                                            <th>Q. Dicom Data Upload</th>
                                            <th>Options Upload</th>
                                            <th>Image Upload</th>
                                            <th>Statements Upload</th>
                                            <th>Added On</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            questions && questions.length > 0 && questions.map((question: any, index: number) => {
                                                return (
                                                    <QuestionTable
                                                        tableData={question}
                                                        questionNum={index + 1}
                                                        reload={getSectionQuestions}
                                                        sections={sections}
                                                        handleViewClick={handleViewClick}
                                                    />
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    }
                    cardClass="mt-3"
                />



            </Container>
        </>
    )
}