import { faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'components/Loader/Loader';
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { ResultSerivces } from 'Services/resultService';
import { parse } from 'json2csv';

interface IMarksModal {
    show: any,
    onHide: any,
    examId: any
}

const MarksModal = ({ show, onHide, examId }: IMarksModal) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [marking, setMarking] = useState<any>({
        marksForCorrectOption: "",
        // marksForIncorrectOption: "0",
        marksWhenIsDeletedTrue: "",
        marksForIncorrectOption: ""
    });


    const onDownload = (data: any) => {

        fetch(data).then(response => {
            response.blob().then(blob => {
              
            })
        })
    }


    const handleMarking = (e: any) => {
        setMarking({ ...marking, [e.target.name]: e.target.value });
    }


    const generateResult = async (e: any) => {
        setLoading(true);
        await toast.promise(
         async() => await ResultSerivces.generateResult(examId, marking).then(async (res) => {
            if (res.status === 200) {
                // console.log(res.data);
                const csv = parse(res.data);
                // console.log(csv);
                const blob = new Blob([csv], { type: 'text/csv' });
                const fileURL = URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'results.csv';
                alink.click();
                onHide();
                // onDownload(csv);
            }
        }).catch(err => {
            toast.error("Unable to generate results")
        }),{
            pending: "Generating Results",
            success: "Results Generated",
            error: "Unable to generate results"
        })
        setLoading(false);
    }


    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Generate Result
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Marks for correct Option</Form.Label>
                            <Form.Control type="number" name="marksForCorrectOption" onChange={handleMarking} required />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Negative Marks</Form.Label>
                            <Form.Control type="number" name="marksForIncorrectOption" defaultValue={0} onChange={handleMarking} readOnly />
                        </Form.Group> */}
                         <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Marks for Incorrect Option</Form.Label>
                            <Form.Control type="number" name="marksForIncorrectOption" onChange={handleMarking} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Marks for deleted Question</Form.Label>
                            <Form.Control type="number" name="marksWhenIsDeletedTrue" onChange={handleMarking} required />
                        </Form.Group>
                        <div className="d-flex align-item-center">
                            <Button variant="primary" type="button" className="d-flex float-right me-3" onClick={generateResult} disabled={loading}>
                                <FontAwesomeIcon icon={faGears} className="me-1" />  Generate
                            </Button>
                            <Button variant="danger" type="button" className="ms-2" onClick={onHide}>
                                Cancel
                            </Button>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MarksModal